<template>
  <v-footer
    id="pages-core-footer"
    absolute
    color="transparent"
    dark
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <!--<v-col-->
        <!--  v-for="(link, i) in links"-->
        <!--  :key="i"-->
        <!--  class="text-center"-->
        <!--  cols="6"-->
        <!--  md="auto"-->
        <!--&gt;-->
        <!--  <a-->
        <!--    :href="link.href"-->
        <!--    class="mr-0"-->
        <!--    rel="noopener"-->
        <!--    target="_blank"-->
        <!--    v-text="link.text"-->
        <!--  />-->
        <!--</v-col>-->

        <!--<v-spacer class="hidden-sm-and-down" />-->

        <v-col
          cols="12"

        >
          <div class="pt-6 pt-md-0 text-center">
            <a class="mr-0" :href="copyRight.link" target="_blank">{{ copyRight.text }}</a>
            <a v-for="(link, i) in links" :href="link.href" target="_blank">{{ link.text }}</a>
            <a class="mr-0" href="javascript:void(0)">V2.0.0</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
export default {
  name: 'PagesCoreFooter',
  computed: {
    getContactSupport(){
      console.log('pppppppppppppppppppppppppppppp',this.$store.state.contactSupport);
      return this.$store.state.contactSupport;
    },
  },
  watch: {
    getContactSupport: function(ov,nv){
      console.log('watch start……');
      if(this.$store.state.contactSupport){
          //业务处理
        }
      }

  },
  
  data: () => ({
    links: [
      // {
      //   href: '',
      //   text: 'PRIVACY'
      // },
      {
        href: '',
        text: 'CONTACT SUPPORT'
      },
      {
        href: '',
        text: 'Terms of Use'
      },
      // {
      //   href: '#',
      //   text: 'V1.0.0'
      // }
    ],
    copyRight: {
      'link': '',
      'text': ''
    }
  }),
  
  created() {
    let _this = this;
    if (localStorage.config) {
      let config = JSON.parse(localStorage.config);
      if (config.terms) {
        this.links[0].href = config.terms.supportLink;
        this.links[1].href = config.terms.appTermsOfUseLink;
        this.copyRight.link = config.terms.copyright.link;
        this.copyRight.text = config.terms.copyright.text;
      }
    }
    else {
      this.getConfigReturn().then(res => {
        localStorage.config = JSON.stringify(res.data);
        let config = res.data;
        if (config.terms) {
          this.links[0].href = config.terms.supportLink;
          this.links[1].href = config.terms.appTermsOfUseLink;
          this.copyRight.link = config.terms.copyright.link;
          this.copyRight.text = config.terms.copyright.text;
        }
      });
    }
    if(localStorage.client == 'Metlife'){
      _this.links[0].href = 'https://my.metlife.com';
      // setInterval(function(){
      //    _this.getContactSupportState();
      //   //  console.log(_this.$store.state.contactSupport);
      // },2000)
    }
   
  },
  methods:{
    getContactSupportState(){
      let _this = this;
      // if(_this.$store.state.contactSupport && _this.$store.state.contactSupport != ''){
      //    _this.links[0].href = _this.$store.state.contactSupport;
      // }
       _this.links[0].href = 'https://my.metlife.com';
    }
  }
}
</script>

<style lang="sass">
#pages-core-footer
  a
    padding: 12px
    padding-left: 0
    color: black
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase

    &::before
      content: '|'
      margin-right: 12px

    &:first-child
      &::before
        content: ''
        margin-right: 0px

</style>
